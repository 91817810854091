<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img fluid src="@/assets/images/logo/logo.png" alt="Fenix" />
          <h2 class="brand-text text-primary ml-1">
            Fenix
          </h2>
        </b-link>
        <b-card-title class="mb-1">
          Consulta tu Estado
        </b-card-title>
        <b-card-text class="mb-2">
          Buscar envios por <b>Número de sello</b>, <b>Referencia</b>, <b>Guía de transportadora</b>, <b>Tracking Casillero</b>
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Digitar el identificador"
              label-for="no-guia"
            >
              <validation-provider
                #default="{ errors }"
                name="Digitar el identificador"
                rules="required"
              >
                <b-form-input
                  id="no-guia"
                  v-model="noGuia"
                  :state="errors.length > 0 ? false:null"
                  name="no-guia"
                 />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Consultar Estado
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Iniciar sesión
          </b-link>
        </b-card-text>

      </b-card>

      <b-modal
          ref="state-modal"
          centered
          size="lg"
          ok-only
          ok-title="Cerrar"
          ok-variant="info"
          title="Consultar Estado"
          modal-class="modal-info"
      >
        <b-card-text>
          <h5>Consulta de Estado</h5>
          <b-list-group>
            <b-list-group-item v-if="estado.Novedad"><b>Novedad: </b> {{ estado.Novedad }}</b-list-group-item>
            <b-list-group-item v-if="estado.Fecha_Entrega"><b>Fecha Entrega: </b>{{ estado.Fecha_Entrega }}</b-list-group-item>
            <b-list-group-item v-if="estado.Sello"><b>Sello: </b>{{ estado.Sello }}</b-list-group-item>
            <b-list-group-item v-if="estado.Referencia"><b>Referencia: </b>{{ estado.Referencia }}</b-list-group-item>
            <b-list-group-item v-if="estado.Destino"><b>Destino: </b>{{ estado.Destino }}</b-list-group-item>
            <b-list-group-item v-if="estado.Peso"><b>Peso: </b>{{ estado.Peso }}</b-list-group-item>
            <b-list-group-item v-if="estado.Peso_volumen"><b>Peso Volumen: </b>{{ estado.Peso_volumen }}</b-list-group-item>
            <b-list-group-item v-if="estado.descripcion_transportadora"><b>Descripción Transportadora: </b>{{ estado.descripcion_transportadora }}</b-list-group-item>
            <b-list-group-item v-if="estado.url_transportadora"><b>Url Transportadora: </b>{{ estado.url_transportadora }}</b-list-group-item>
            <b-list-group-item v-if="estado.Estado_Envio"><b>Estado de Envío: </b>{{ estado.Estado_Envio }}</b-list-group-item>
            <b-list-group-item v-if="estado.Guia_Servientrega"><b>Guía Servientrega: </b>{{ estado.Guia_Servientrega }}</b-list-group-item>
          </b-list-group>
          <hr/>
          <h5>Seguimiento</h5>
          <b-table responsive="sm" :items="items" :fields="fields" small bordered />
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BTable } from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BTable,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      noGuia: '',
      required,
      items: [],
      fields: [
        "lugar",
        "fecha",
        "estado",
        "EstadoAlt",
        "observacion",
      ],
      estado:{}
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.sendStatus()
        }
      })
    },
    sendStatus(){
      this.$http
          .get(
              `api/consulta-estado/${this.noGuia}/${process.env.VUE_APP_APPID}`
          )
          .then((response) => {
            this.estado = response.data.datos
            this.items = response.data.datos.Seguimiento
            this.$refs['state-modal'].show()
          })
          .catch((errors) => {
            // console.log(errors);
          });

    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
